import LocalStorageManager from "@/LocalStorageManager";

const SharedPageMixin = {
  computed: {
    isAdmin() {
      const ret = LocalStorageManager.shared.getIsAdmin();
      return ret;
    },
    appropriateComponent() {
      return this.isAdmin ? "AdminComponent" : "UserComponent";
    },
  },
};

export default SharedPageMixin;
